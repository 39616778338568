export default function toggleHamburger() {
  document.getElementById('site-nav-banner').classList.toggle('hide')
}

export function mobileHeader() {
  const w = window.outerWidth;

  if(w <= 955) {
    document.getElementById('header-address').classList.add('hide')
    document.getElementById('header-hours').classList.add('hide')
    document.getElementById('site-nav-banner').classList.add('hide')
    document.getElementById('hamburger').classList.remove('hide')
  } else {
    document.getElementById('header-address').classList.remove('hide')
    document.getElementById('header-hours').classList.remove('hide')
    document.getElementById('site-nav-banner').classList.remove('hide')
    document.getElementById('hamburger').classList.add('hide')
  }
}